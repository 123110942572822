import * as React from "react";
import { Link } from "gatsby";
import Page from "./../components/Page";
import Section from "./../components/Section";
import ButtonLink from "./../components/ButtonLink";
import SEO from "./../components/SEO";

// styles

// markup
const NotFoundPage = () => {
  return (
    <Page className="flex-1">
      <SEO title="Page Not Found | OHIF" />
      <main className="mx-auto pt-32 pl-10 bg-blue-800 w-full">
        <h1 className="text-white text-3xl font-bold mb-4 w-full">
          You Broke The Internet!
        </h1>
        <p className="text-white text-lg">
          We are just kidding ... The page you were looking for cannot be found.
          Navigate back to the{" "}
          <Link to="/" className="text-blue-100">
            home page.
          </Link>
        </p>
      </main>
    </Page>
  );
};

export default NotFoundPage;
